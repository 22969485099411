<template>
  <div
    v-if="!showLoader"
    class="my-4">
    <m-page-title title="Products" />
    <b-row class="d-flex my-3">
      <b-col cols="12">
        <b-card>
          <b-row class="align-items-center mb-2">
            <b-colxx
              md="6"
              sm="12"
              ><b-form
                id="navbar-search-main"
                @submit.prevent="
                  () => {
                    ;(current_page = 1), getProductList()
                  }
                ">
                <b-form-group class="mb-0">
                  <b-input-group class="">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-search"></i></span>
                    </div>
                    <b-form-input
                      v-model.trim="searchProductKeyword"
                      placeholder="Search for a product by name or product id"
                      type="text">
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-form>
            </b-colxx>
            <b-colxx
              md="3"
              sm="12">
              <b-form-group
                id="supplier-type-fieldset"
                label="Supplier"
                label-for="supplier-select"
                class="field-label-form-group">
                <b-form-select
                  id="supplier-select"
                  :options="supplierList"
                  v-model="searchBySupplier"
                  value-field="erp_supplier_id"
                  text-field="name" />
              </b-form-group>
            </b-colxx>
            <b-colxx
              md="3"
              sm="12">
              <b-button
                variant="success"
                @click="
                  () => {
                    getProductList()
                  }
                "
                >Search</b-button
              >
              <b-button
                variant="info"
                @click="clearProductSearch()"
                >Clear</b-button
              >
            </b-colxx>
          </b-row>
          <div class="row">
            <b-colxx
              sm="4"
              md="2">
              <b-form-group
                label="Inactive"
                class="field-label-form-group mb-4">
                <b-form-checkbox v-model="searchInactiveYN" />
              </b-form-group>
            </b-colxx>
            <b-colxx
              sm="4"
              md="2">
              <b-form-group
                label="No Adjusted Cost"
                class="field-label-form-group mb-4">
                <b-form-checkbox v-model="searchNoCostYN" />
              </b-form-group>
            </b-colxx>
            <b-colxx
              sm="4"
              md="2">
              <b-form-group
                label="Supplier is Blank"
                class="field-label-form-group mb-4">
                <b-form-checkbox v-model="searchSupplierIsBlankYN" />
              </b-form-group>
            </b-colxx>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-table
      id="product-table"
      ref="__productTable"
      responsive
      head-variant="dark"
      :items="productList"
      :fields="product_list_fields"
      :current-page="current_page"
      :is-busy="saveInProgress"
      per-page="0">
      <template #cell(actions)="row">
        <i
          v-if="permissions.includes('CanEditProduct')"
          class="fas fa-pencil text-default icon-pointer"
          @click="goProductDetail(row.item, row.index)" />
      </template>
      <template #cell(sname)="row">
        {{ row.item.sname }}
        <i
          class="fas"
          :class="row.item.binactive ? 'fa-do-not-enter text-warning' : ''" />
      </template>
      <template #cell(ignore_for_commissions_yn)="row">
        <i
          v-if="row.item.ignore_for_commissions_yn"
          class="fas fa-circle-xmark text-danger" />
      </template>
    </b-table>
    <b-pagination
      v-model="current_page"
      :total-rows="total_rows"
      :per-page="per_page"
      :number-of-pages="pageTotal"
      size="sm"
      class="my-0"
      aria-controls="product-table"
      @input="getProductList(current_page)"></b-pagination>

    <div id="product-modal-div">
      <b-modal
        id="product-modal"
        v-model="viewProductModal"
        title="Product Details"
        centered
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="info"
        header-text-variant="white"
        size="lg">
        <b-alert
          v-model="showProductSaveAlert"
          :variant="productSaveAlertVariant"
          >{{ productSaveAlertMessage }}</b-alert
        >
        <b-form-row>
          <b-colxx
            sm="12"
            md="4">
            <b-form-group
              label="UPC"
              class="field-label-form-group">
              <b-form-input
                v-model="editProduct.spartcode"
                type="text"
                readonly />
            </b-form-group>
          </b-colxx>
          <b-colxx
            sm="12"
            md="8">
            <b-form-group
              label="Name"
              class="field-label-form-group">
              <b-form-input
                v-model="editProduct.sname"
                type="text"
                readonly />
            </b-form-group>
          </b-colxx>
          <b-colxx
            sm="12"
            md="4">
            <m-text-input
              id="unit-cost-input"
              label="Unit Cost"
              type="number"
              :value="editProduct.adjusted_unit_cost"
              :rules="{ required: true }"
              :alternative="false"
              :group="false"
              name="Unit Cost"
              @update-value="
                (input) => {
                  editProduct.adjusted_unit_cost = input
                }
              " />
          </b-colxx>
          <b-colxx
            sm="12"
            md="4">
            <m-text-input
              id="sage-cost"
              label="Sage Cost"
              :value="editProduct.sage_unit_cost"
              :alternative="false"
              :group="false"
              :readonly="true"
              name="Sage Cost" />
          </b-colxx>
          <b-colxx
            sm="12"
            md="4">
            <m-text-input
              id="sku-input"
              label="Item #"
              :value="editProduct.sku"
              :alternative="false"
              :group="false"
              name="Item #"
              @update-value="
                (input) => {
                  editProduct.sku = input
                }
              " />
          </b-colxx>
          <b-colxx
            sm="12"
            md="6">
            <b-form-group
              label="Supplier *"
              class="field-label-form-group mt-2">
              <b-form-select
                v-model="editProduct.supplier"
                :options="supplierList"
                value-field="erp_supplier_id"
                text-field="name"
                class="custom-input-select" />
            </b-form-group>
          </b-colxx>
          <b-colxx
            sm="12"
            md="4">
            <b-form-group
              label="Ignore for Commissions"
              class="field-label-form-group mt-2">
              <b-form-checkbox v-model="editProduct.ignore_for_commissions_yn" />
            </b-form-group>
          </b-colxx>
          <b-colxx
            xxs="sm"
            md="6">
            <b-form-group
              label="Modified By"
              class="field-label-form-group mb-4">
              <b-form-input
                v-model="editProduct.modified_by_name"
                readonly
                type="text" />
            </b-form-group>
          </b-colxx>
          <b-colxx
            xxs="sm"
            md="6">
            <b-form-group
              label="Modified At"
              class="field-label-form-group mb-4">
              <!-- {{ new Date(editProduct.updated_at).toLocaleString() }} -->
              <b-form-input
                :value="new Date(editProduct.modified_at).toLocaleString()"
                readonly
                type="text" />
            </b-form-group>
          </b-colxx>
        </b-form-row>
        <b-row class="my-3">
          <b-colxx sm="12">
            <b-card-text>
              Product cost history is based on fiscal quarter. Any changes made to the product cost will be effective the previous fiscal quarter.
            </b-card-text>
          </b-colxx>
        </b-row>
        <b-row>
          <b-colxx
            md="12"
            sm="12">
            <b-table
              id="history-table"
              responsive
              head-variant="dark"
              :items="editProduct.cost_history"
              :fields="cost_history_fields">
            </b-table>
          </b-colxx>
        </b-row>
        <template #modal-footer="{ save, cancel }">
          <div v-if="!saveProductComplete">
            <b-button
              v-if="permissions.includes('CanEditProduct')"
              class="mr-2"
              variant="info"
              :disabled="disableSaveButton"
              @click="save = saveProduct()">
              {{ saveButtonLabel }}
            </b-button>
            <b-button
              variant="warning"
              :disabled="saveInProgress"
              @click="cancel = closeSaveProduct()">
              Cancel
            </b-button>
          </div>
          <div v-if="saveProductComplete">
            <b-button
              variant="info"
              @click="cancel = closeSaveProduct()">
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"

  export default {
    name: "ProductHome",
    components: {},

    data() {
      return {
        productList: [],
        product_list_fields: [
          {
            label: "",
            key: "actions",
          },
          {
            label: "Name",
            key: "sname",
          },
          {
            label: "UPC",
            key: "spartcode",
          },
          {
            label: "Ignore",
            key: "ignore_for_commissions_yn",
          },
          {
            label: "Adjusted Unit Cost",
            key: "adjusted_unit_cost",
          },
          {
            label: "Sage Unit Cost",
            key: "sage_unit_cost",
          },
          {
            label: "Supplier",
            key: "supplier_name",
          },
          {
            label: "Item #",
            key: "sku",
          },
        ],
        current_page: 1,
        per_page: 10,
        total_rows: null,
        prev_page_link: "",
        next_page_link: "",
        error_message: "",
        searchProductKeyword: "",

        editProduct: {},
        editProductIndex: null,
        supplierList: [],
        searchBySupplier: null,
        searchInactiveYN: false,
        searchNoCostYN: false,
        searchSupplierIsBlankYN: false,

        cost_history_fields: [
          {
            label: "Cost",
            key: "unit_cost",
          },
          {
            label: "From",
            key: "effective_from_date",
          },
          {
            label: "To",
            key: "effective_to_date",
          },
          // {
          //   label: "User",
          //   key: "created_by_name",
          // },
        ],

        viewProductModal: false,

        saveInProgress: false,
        saveProductComplete: false,
        showProductSaveAlert: false,
        productSaveAlertVariant: "",
        productSaveAlertMessage: "",
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "permissions"]),
      ...mapGetters("common", ["currentPage", "showLoader"]),

      pageTotal() {
        var page_total = 1
        page_total = Math.ceil(this.total_rows / this.per_page)
        return page_total
      },

      saveButtonLabel() {
        if (this.saveInProgress === true) {
          return "Saving..."
        } else {
          return "Save"
        }
      },

      disableSaveButton() {
        if (this.saveInProgress || this.editProduct.adjusted_unit_cost === null || this.editProduct.supplier === null) {
          return true
        }
        return false
      },
    },

    created() {},

    async mounted() {
      this.$store.dispatch("common/showLoader", true)
      document.title = "Product List"
      await django_session
        .get("erp/product/supplier/list/", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => (this.supplierList = response.data.filter((obj) => obj.is_inactive_yn === false)))
        .catch((error) => {
          console.log(error.response)
        })
      this.getProductList()
      this.$store.dispatch("common/showLoader", false)
    },

    methods: {
      async getProductList() {
        this.$store.dispatch("common/showLoader", true)
        await django_session
          .get("sage/product-list/", {
            params: {
              page: this.current_page,
              keyword: this.searchProductKeyword,
              supplier: this.searchBySupplier,
              inactive: this.searchInactiveYN,
              no_cost: this.searchNoCostYN,
              supplier_is_blank: this.searchSupplierIsBlankYN,
            },
          })
          .then((response) => {
            this.total_rows = response.data.count
            this.prev_page_link = response.data.links.previous
            this.next_page_link = response.data.links.next
            // this.current_page = response.data.page;
            this.productList = response.data.results
          })
          .catch((error) => {
            console.log(error)
          })
        this.$store.dispatch("common/showLoader", false)
        this.getInactive = false
        this.getNoCost = false
      },

      clearProductSearch() {
        this.$store.dispatch("common/showLoader", true)
        this.current_page = 1
        this.searchProductKeyword = ""
        this.searchBySupplier = null
        this.searchInactiveYN = false
        this.searchNoCostYN = false
        this.searchSupplierIsBlankYN = false
        this.getProductList()
        this.$store.dispatch("common/showLoader", false)
      },

      goProductDetail(item, index) {
        this.editProduct = JSON.parse(JSON.stringify(item))
        // if (item.cost_history.length > 0) {
        //   this.editProduct.adjusted_unit_cost = item.cost_history[0].unit_cost
        // }
        let checkProperty = this.editProduct.ignore_for_commissions_yn === undefined
        if (checkProperty) {
          Object.assign(this.editProduct, { ignore_for_commissions_yn: false })
        }
        this.editProductIndex = index
        this.viewProductModal = true
      },

      async saveProduct() {
        this.saveInProgress = true
        await django_session
          .patch("erp/product/" + this.editProduct.lid + "/", {
            sage_product_id: this.editProduct.lid,
            erp_product_id: this.editProduct.erp_product_id,
            ignore_for_commissions_yn: this.editProduct.ignore_for_commissions_yn,
            adjusted_unit_cost: Number(this.editProduct.adjusted_unit_cost),
            sku: this.editProduct.sku,
            supplier_id: this.editProduct.supplier,
          })
          .then((response) => {
            console.log(response.data)
            // this.editProduct.supplier_name = response.data.supplier_name
            this.editProduct.modified_by_name = response.data.modified_by_name
            this.editProduct.modified_by = response.data.modified_by
            this.editProduct.modified_at = response.data.modified_at
            // this.editPro
            this.productList[this.editProductIndex].ignore_for_commissions_yn = response.data.ignore_for_commissions_yn
            this.productList[this.editProductIndex].sku = response.data.sku
            this.productList[this.editProductIndex].supplier = response.data.supplier
            this.productList[this.editProductIndex].supplier_name = response.data.supplier_name
            this.productList[this.editProductIndex].adjusted_unit_cost = response.data.adjusted_unit_cost
            this.productList[this.editProductIndex].modified_at = response.data.modified_at
            this.productList[this.editProductIndex].modified_by_name = response.data.modified_by_name
            this.productList[this.editProductIndex].modified_by = response.data.modified_by
            this.productList[this.editProductIndex].cost_history = response.data.cost_history
            this.editProduct.cost_history = response.data.cost_history
            this.saveInProgress = false
            this.productSaveAlertMessage = "Product details updated successfully."
            this.productSaveAlertVariant = "success"
            this.showProductSaveAlert = true
            this.saveProductComplete = true
          })
          .catch((error) => {
            console.log(error)
            this.saveInProgress = false
            this.productSaveAlertMessage = "Error saving product detail."
            this.productSaveAlertVariant = "danger"
            this.showProductSaveAlert = true
            this.saveProductComplete = true
          })
      },

      closeSaveProduct() {
        this.viewProductModal = false
        this.productSaveAlertMessage = ""
        this.productSaveAlertVariant = ""
        this.showProductSaveAlert = false
        this.saveProductComplete = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  span {
    display: block;
  }
</style>
